var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.container},[_c('Loader',{attrs:{"isLoading":_vm.isLoader}}),_c('adw-button',{class:_vm.$style.createOrderBtn,attrs:{"link":_vm.getRoute({
        route: _vm.$options.ADDSELLERS_ADMIN_ROUTES.DEALERS.ORDERS.CREATE,
        params: { id: _vm.$route.params.id },
      })}},[_vm._v("Создать заказ")]),_c('vue-good-table',{class:_vm.$style.table,attrs:{"columns":_vm.columns,"rows":_vm.list,"styleClass":"vgt-table striped"},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'createdAt')?_c('span',[_vm._v(" "+_vm._s(_vm.fomateDateRow(props.row.createdAt))+" ")]):(props.column.field == 'status')?_c('span',[_vm._v(" "+_vm._s(_vm.formateStatusRow(props.row.status))+" ")]):(props.column.field == 'deliveryAt')?_c('span',[_vm._v(" "+_vm._s(_vm.fomateDateRow(props.row.deliveryAt))+" ")]):(props.column.field == 'warehouse')?_c('span',[_vm._v(_vm._s(_vm.formatWarehouse(props.row.warehouse)))]):(props.column.field == 'createdManager')?_c('router-link',{attrs:{"to":`/sellers/managers/edit/${props.row.createdManagerId}`}},[_vm._v(" "+_vm._s(props.row.createdManager != null ? props.row.createdManager.name : '')+" ")]):(props.column.field == 'action')?_c('span',[_c('router-link',{attrs:{"to":{
            name: 'editSellersOrder',
            params: { id: props.row.id },
          }}},[_c('Icon',{class:_vm.$style.plus,attrs:{"name":"plus"}})],1)],1):(props.column.field == 'delete')?_c('span',{on:{"click":function($event){return _vm.deleteOrder(props.row)}}},[_c('Icon',{class:_vm.$style.trash,attrs:{"name":"trash"}})],1):_vm._e()]}}])}),(_vm.lastPage > 1)?_c('Pagination',{attrs:{"curPage":_vm.currentPage,"lastPage":_vm.lastPage},on:{"go-next":function($event){return _vm.goNext()},"go-prev":function($event){return _vm.goPrev()}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
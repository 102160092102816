<template>
  <div :class="$style.container">
    <Loader :isLoading="isLoader" />
    <adw-button
      :class="$style.createOrderBtn"
      :link="
        getRoute({
          route: $options.ADDSELLERS_ADMIN_ROUTES.DEALERS.ORDERS.CREATE,
          params: { id: $route.params.id },
        })
      "
      >Создать заказ</adw-button
    >
    <vue-good-table
      :class="$style.table"
      :columns="columns"
      :rows="list"
      styleClass="vgt-table striped"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'createdAt'">
          {{ fomateDateRow(props.row.createdAt) }}
        </span>
        <span v-else-if="props.column.field == 'status'">
          {{ formateStatusRow(props.row.status) }}
        </span>
        <span v-else-if="props.column.field == 'deliveryAt'">
          {{ fomateDateRow(props.row.deliveryAt) }}
        </span>
        <span v-else-if="props.column.field == 'warehouse'">{{
          formatWarehouse(props.row.warehouse)
        }}</span>
        <router-link
          :to="`/sellers/managers/edit/${props.row.createdManagerId}`"
          v-else-if="props.column.field == 'createdManager'"
        >
          {{
            props.row.createdManager != null
              ? props.row.createdManager.name
              : ''
          }}
        </router-link>
        <span v-else-if="props.column.field == 'action'">
          <router-link
            :to="{
              name: 'editSellersOrder',
              params: { id: props.row.id },
            }"
          >
            <Icon name="plus" :class="$style.plus" />
          </router-link>
        </span>
        <span
          v-else-if="props.column.field == 'delete'"
          @click="deleteOrder(props.row)"
        >
          <Icon name="trash" :class="$style.trash" />
        </span>
      </template>
    </vue-good-table>
    <Pagination
      v-if="lastPage > 1"
      @go-next="goNext()"
      @go-prev="goPrev()"
      :curPage="currentPage"
      :lastPage="lastPage"
    />
  </div>
</template>

<script>
import delivery from '@/delivery'
import Pagination from '@/components/atoms/Paginations.vue'
import Loader from '@/components/atoms/LoadingBar.vue'
import Icon from '@/components/atoms/Icon'

import {
  formatDate,
  formateStatusOrder,
  formatWarehouse,
} from '@/helpers/index.js'
import { ADDSELLERS_ADMIN_ROUTES, getRoute } from '@/constants/routing'

export default {
  ADDSELLERS_ADMIN_ROUTES,
  components: {
    Pagination,
    Icon,
    Loader,
  },
  mounted() {
    this.getDealersOrders()
  },
  data() {
    return {
      columns: [
        {
          label: 'Номер',
          field: 'name',
          tooltip: 'Номер заказа',
        },
        {
          label: 'Оформлен',
          field: 'createdAt',
        },
        {
          label: 'Сумма',
          field: 'price',
        },
        {
          label: 'Статус',
          field: 'status',
        },
        {
          label: 'Дата доставки',
          field: 'deliveryAt',
        },
        {
          label: 'Юр.лицу на кого пришел заказ',
          field: 'warehouse',
        },
        {
          label: 'Юр.лицо от кого получили заказ',
          field: 'recipientName',
        },
        {
          label: 'Создан менеджером',
          field: 'createdManager',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
        },
        {
          label: '',
          field: 'delete',
          sortable: false,
        },
      ],
      currentPage: this.$store.state.dealersOrders.page,
      limitPage: 10,
      lastPage: 1,
      list: null,
      isLoader: false,
    }
  },
  methods: {
    async getDealersOrders() {
      this.isLoader = true
      const { value, error } =
        await delivery.AddwineCore.DealersActions.getDealersOrders({
          id: this.$route.params.id,
          page: this.currentPage,
          limit: this.limitPage,
        })
      if (error) return
      this.list = value.data
      this.lastPage = Math.ceil(value.meta.count / this.limitPage)
      this.isLoader = false
    },
    async goNext() {
      if (this.currentPage < this.lastPage) {
        this.currentPage++
        this.$store.commit('setPage', this.currentPage)
        await this.getDealersOrders()
      }
    },
    async goPrev() {
      if (this.currentPage > 1) {
        this.currentPage--
        this.$store.commit('setPage', this.currentPage)
        await this.getDealersOrders()
      }
    },
    fomateDateRow(date) {
      return formatDate(date)
    },
    formateStatusRow(status) {
      return formateStatusOrder(status)
    },
    formatWarehouse(warehouse) {
      return formatWarehouse(warehouse)
    },
    async deleteOrder(order) {
      if (
        confirm(
          `Вы действительно хотите навсегда удалить заказ "${order.name}"?`,
        )
      ) {
        const result = await delivery.AddwineCore.OrdersActions.delete(order.id)
        if (result.error) {
          alert('Ошибка удаления заказа!')
        } else {
          this.getDealersOrders()
          alert('Заказ успешно удален')
        }
      }
    },
    getRoute(route, params) {
      return getRoute(route, params)
    },
  },
}
</script>

<style lang="scss" module>
.container {
  padding: 1rem;
  h2 {
    padding: 1rem 0;
  }

  .createOrderBtn {
    margin-top: 2rem;
  }

  .table {
    margin-top: 2rem;
  }

  .status {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: $error;
  }
  .successStatus {
    background-color: $complite;
  }
  .plus {
    fill: $light-gray;
    width: 1rem;
    height: 1rem;
  }
  .trash {
    fill: $cyan;
    width: 1rem;
    height: 1rem;
    cursor: pointer;
  }
}
</style>
